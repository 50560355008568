<template>
  <div
    class="flex flex-column align-items-center content-error-page md:mt-s10 px-s4"
    style="width: 620px"
  >
    <Icon name="error404" :size="$store.state.layout.isMobile ? '160' : '240'" />
    <div class="mt-s6 h1" :class="$store.state.layout.isMobile ? 'h3' : 'h1'">
      {{ $t('error.404.pageNotFound') }}
    </div>
    <div
      class="mt-s4 text-center"
      v-html="$t('error.404.message')"
      :class="{ 'text-sm': $store.state.layout.isMobile }"
    ></div>
    <div class="mt-s6">
      <Button class="button-lg button-primary" :label="$t('goToHome')" @click="$router.push('/')" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon';
export default {
  name: '404',
  components: { Icon },
};
</script>

<style scoped></style>
